<template>
  <main class="page page-activity-open">
    <BreadcrumbsComponent :title="activity.title" v-if="activity.title" :links="parents" />
    <div class="page__container">
      <div class="page__content">
        <AsideNavigatorComponent :routes="activities" />
        <div class="page-activity-open__content">
          <EditorJSComponent :text="activity | getArticle" v-if="activity.description" />
          <h2 v-if="childs.length" class="page-activity-open__article">Подразделы</h2>
          <div class="page-activity-open__list">
            <ActivityItem v-for="(activity, i) in childs" :key="i" :data="activity" white />
          </div>
          <div class="page-activity-open__tabs" v-if="faqCategories.length">
            <h2 class="page-activity-open__article">Вопросы и ответы</h2>
            <div class="page-activity-open__tabs-nav-list">
              <div
                v-for="(item, i) in faqCategories"
                :key="item.id"
                @click="activityTab = i"
                class="page-activity-open__tabs-nav-item"
                :class="{ 'page-activity-open__tabs-nav-item--active': activityTab === i }"
              >
                <span>{{ item.title }}</span>
              </div>
            </div>
            <div v-for="(item, i) in faqCategories" :key="item.id">
              <FaqComponent :faqs="item.faqs" v-if="activityTab === i" />
            </div>
          </div>
          <h2 class="page-activity-open__article" v-if="documents.length">Документы</h2>
          <ul class="page-activity-open__files" v-if="documents.length">
            <li v-for="(file, i) in documents" :key="i">
              <ListDocumentItem :data="file" activity link />
            </li>
          </ul>
          <div class="page-activity-open__published" v-if="activity.created_at">
            <span class="page-activity-open__published-title">Опубликовано:</span>
            <time
              class="page-activity-open__published-content"
              :datetime="activity.published_at || activity.created_at | robotDate"
            >
              {{ activity.published_at || activity.created_at | humanDateShort(false) }}
            </time>
          </div>
          <div class="page-activity-open__published">
            <span class="page-activity-open__published-title">Количество просмотров:</span>
            <span class="page-activity-open__published-content">{{ count }}</span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import FaqComponent from "./components/FaqComponent.vue";
import BreadcrumbsComponent from "components/navigation/BreadcrumbsComponent.vue";
import ACTIVITY_OPEN_PAGE from "gql/pages/ActivityOpenPage.graphql";
import AsideNavigatorComponent from "components/navigation/AsideNavigatorComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import moment from "moment";
import ActivityItem from "components/ActivityItem.vue";
import ListDocumentItem from "components/ListDocumentItem.vue";
import ADD_VIEW from "gql/mutations/AddView.graphql";

export default {
  name: "ActivityOpenPage",
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: ACTIVITY_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
          type_id: store.state._types.COUNTER_ACTIVITY_TYPE,
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("activity/save", data);
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  data() {
    return {
      activityTab: 0,
    };
  },
  computed: {
    faqCategories() {
      return this.$store.state.activity.activities_item.faq_categories;
    },
    childs() {
      return this.activity.child_activities.map((c) => this.getAsLink(c));
    },
    parents() {
      let parents = this.getParents(this.activity, []);
      return [
        {
          title: "Деятельность",
          to: {
            name: "activity",
          },
        },
        ...parents.reverse(),
      ];
    },
    activities() {
      return this.$store.state.activity.activities_paginate.data.map((a) => this.getAsLink(a));
      // array.push(this.getAsLink(this.activity));
      // return array.sort((a, b) => {
      //   return moment(a.updated_at).format("X") - moment(b.updated_at).format("X");
      // });
    },
    activity() {
      return this.$store.state.activity.activities_item;
    },
    documents() {
      return this.activity.files.map((f) => ({
        id: f.id,
        file: f.file,
        title: f.title,
        published_at: f.published_at,
      }));
    },
    count() {
      return this.$store.state.activity.item_views_count;
    },
  },
  mounted() {
    this.counterUpdate();
  },
  methods: {
    counterUpdate() {
      const variables = {
        id: parseInt(this.$route.params.id),
        type_id: this.$store.state._types.COUNTER_ACTIVITY_TYPE,
      };
      this.$apollo
        .mutate({
          mutation: ADD_VIEW,
          variables: variables,
        })
        .then(({ data }) => {
          if (data && data.AddView) {
            console.log("успешно");
          }
        })
        .catch(({ graphQLErrors }) => {
          this.parseGqlErrors(graphQLErrors);
        });
    },
    getParents(item, array) {
      let newArray = array;
      if (item.parent) {
        newArray.push(this.getAsLink(item.parent));
        return this.getParents(item.parent, newArray);
      }
      return array;
    },
    getAsLink(item) {
      return {
        title: item.title,
        updated_at: item.updated_at,
        to: {
          name: "activity-item",
          params: {
            id: item.id,
          },
        },
      };
    },
  },
  metaInfo() {
    const meta = this.$store.state.activity.activities_item;
    return {
      title: meta?.meta_title || meta?.title,
      meta: [
        {
          name: "description",
          content: meta?.meta_description,
        },
        {
          name: "keywords",
          content: meta?.meta_keywords,
        },
      ],
    };
  },
  components: {
    ListDocumentItem,
    ActivityItem,
    EditorJSComponent,
    AsideNavigatorComponent,
    BreadcrumbsComponent,
    FaqComponent,
  },
};
</script>

<style lang="stylus">

.page-activity-open {
  display grid
  grid-gap 50px
  margin-bottom 50px
  +below(1024px) {
    grid-gap 30px
    margin-bottom 30px
  }
  +below(600px) {
    .aside-navigator__button {
      max-width 100%
    }
  }
  & .page__content {
    grid-gap 30px
    align-items start
    grid-template-columns 328px 1fr
    +below(1024px) {
      grid-template-columns 1fr
    }
  }
  &__tabs {
  }
  &__tabs-nav-list {
    display flex
    flex-wrap wrap
    gap 10px
    padding 10px
    background-color var(--white)
    border-radius 10px
    margin-bottom 10px
  }
  &__article {
    color var(--dark)
  }
  &__tabs-nav-item {
    transition .2s
    cursor pointer
    border-radius 5px
    padding 10px 20px

    &:active {
      transform scale(0.95)
    }
  }
  &__tabs-nav-item--active {
    color var(--white)
    background-color var(--main)
  }
  &__list {
    display grid
    grid-template-columns 1fr
    grid-gap 30px

    +below(600px) {
      grid-gap 15px
    }
  }

  &__files {
    display grid
    grid-gap 15px

    li {
      display inline-flex
      width 100%
    }
    //display grid
    //grid-template-columns repeat(3, 1fr)
    //grid-gap 30px
    //.document-item-load__title {
    //  word-break break-all
    //}
    //+below(1224px) {
    //  grid-template-columns repeat(2, 1fr)
    //}
    //+below(600px) {
    //  grid-gap 15px
    //  grid-template-columns 1fr
    //}
  }

  &__published {
    display grid
    grid-gap 10px
    margin-top 18px

    &-title {
      font-weight 700
      font-size 1.25em
      line-height 28px
      color var(--dark)
    }

    &-content {
      font-weight 500
      font-size 1.25em
      line-height 28px
      color var(--dark)
    }
  }
}
</style>
